import React from 'react';
// import UIKit from 'uikit';
import {FlaxLogo, ClientLogo } from '../components';
import UserProfile from '../widgets/UserProfile';
import { Logout } from 'src/components/Logout';
import { useAuth0 } from '@auth0/auth0-react';


// const margin = UIKit.margin('#margin', {})

export const PageHeader = (props: React.HTMLAttributes<HTMLElement>) => {
  const {isAuthenticated} = useAuth0();
  return (
    <div className="uk-background-default uk-grid inline">
      <div>
        <div className="uk-align-left uk-card">
          <h2 className="uk-margin-remove-bottom uk-padding-small">
            <FlaxLogo />
            <span className="uk-margin-left uk-text-align-center uk-text-nowrap">
              Flax Disk Triage
            </span>
          </h2>
        </div>
      </div>
      <div className="uk-width-expand">
        <div className="uk-align-left uk-card">
        </div>
      </div>
      <div>
        <div className="uk-card uk-margin-top uk-padding-small uk-align-right uk-valign-center">
          <UserProfile />
        </div>
      </div>
    </div>
  );
};
