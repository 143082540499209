import React, { Fragment } from 'react';
//import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { hideDropDown } from 'src/util/UIUtil';


export const PrimaryNav = () => {
  //  const { isAuthenticated } = useAuth0();
  //  if (! isAuthenticated) {
  //    return <Fragment />
  //  }

  return (
    <Fragment>
      <nav className="uk-navbar-container uk-dropnav uk-flex-column uk-flex-top uk-padding-none"
        data-uk-dropnav data-uk-navbar data-uk-toggle="media: @s; cls: uk-flex-row uk-flex-top; mode: media">
        <button data-uk-toggle="target: .navbar-collapse; cls: uk-visible@s uk-padding-remove"
          className="uk-navbar-toggle uk-padding uk-padding-remove-vertical uk-hidden@s" data-uk-navbar-toggle-icon></button>
        <div className="navbar-collapse uk-visible@s">
          <div className="uk-navbar-left" >
            &nbsp;
            <ul data-uk-toggle="media: @s; cls: uk-navbar-nav uk-padding-remove; mode: media"
              className="uk-nav uk-navbar-nav uk-padding-remove uk-text-uppercase">
              <li>
                <NavLink to="/servers" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Servers</NavLink>
              </li>
              <li>
                <NavLink to="/disks" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Disks</NavLink>
              </li>
              <li>
                <a href="#">Reporting <span data-uk-drop-parent-icon></span></a>
                <div className="uk-dropdown">
                  <ul className="uk-nav uk-dropdown-nav">
                    <li>
                      <NavLink to="/reports/diskhistory" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Disk History</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment >
  );
};

