
import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Spinner } from "src/components/Spinner";
import Alert, {AlertTypes} from "src/components/AlertDisplay"
import { ILoadState } from "src/types";
import { Navigate } from "react-router-dom";
import { IDisk } from "src/types/IDisk";

export const DisksPage = () => {
  const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
  const [disks, setDisks] =  useState<IDisk[]>([]);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)

  useEffect( () => {
    getAccessTokenSilently().then((accessToken) => {
      API.get(accessToken, "/api/v1/serverceivers") 
      .then( res => {
        setDisks(res.data);
        setDisplayState(ILoadState.READY)
      })
    })
    .catch( err => {
      Alert(AlertTypes.ERROR, err.message);
    })
  }, [getAccessTokenSilently])

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  if (isLoading || displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    return <div><h2>Servers</h2>
      <div><Spinner /></div>
    </div> 
  }

  return <div>
    <h2>Servers</h2>


    <table>
    {disks.map( (d,i) => (
      <tr>
        <td className="uk-preserve-width">
        {d.serial}
        </td>
        <td>
          {d.manufacturer}
        </td>
      </tr>
    ))
    }
    </table>
    </div>
}
