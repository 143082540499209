
import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import { Spinner } from "src/components/Spinner";
import Alert, { AlertTypes } from "src/components/AlertDisplay"
import { ILoadState } from "src/types";
import { Navigate } from "react-router-dom";
import { IServer } from "src/types/IServer";

export const ServersPage = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [servers, setServers] = useState<IServer[]>([]);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => {
      API.get(accessToken, "/api/v1/server")
        .then(res => {
          setServers(res.data);
          setDisplayState(ILoadState.READY)
        })
        .catch(err => {
          Alert(AlertTypes.ERROR, err.message);
        })
    })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
      })
  }, [getAccessTokenSilently])

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  if (isLoading || displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    return <div><h2>Servers</h2>
      <div><Spinner /></div>
    </div>
  }

  return <div>
    <h2>Servers</h2>

    <ul>
      {servers.map((s, i) => (
        <li className="uk-list">
          <h2>{s.hostname} - {s.serial}</h2>
          <div className="bank-layout">
            {s.geometry.banks.map(b => (
                <div className="bank-block">
                  {b.uiOrientation == 'v' && <table><tr>
                    {Array.from(Array(b.lastIndex - b.firstIndex + 1).keys()).map(sl => <td className="disk-vertical">{sl + b.firstIndex}</td>
                    )}
                  </tr></table>
                  }
                  {b.uiOrientation == 'h' && <table>
                    {Array.from(Array(b.lastIndex - b.firstIndex + 1).keys()).map(sl => <tr><td className="disk-horizontal">{sl + b.firstIndex}</td></tr>)}
                  </table>
                  }
                </div>
            ))}
          </div>
        </li>
      ))
      }
    </ul>
  </div>
}
