import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, Navigate, Outlet, RouteProps, NavLink, Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import { PageHeader, PrimaryNav } from "./widgets"

import { HomePage } from "./pages/Home";
import { IndexPage } from "./pages/IndexPage"
import { Spinner } from "./components/Spinner";

import Hedgehog from './assets/images/errorhedgehog.png';
import { config } from "./config";

import { ServersPage} from './pages/Servers';
import { DisksPage } from "./pages/Disks";

const App = () => {

  const { isLoading, isAuthenticated, user } = useAuth0();

  if (isLoading) {
    return <div className="uk-align-center">
      <Spinner />
    </div>
  }

  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route path="/" element={<IndexRoute />} />
        <Route path="/servers" element={<RequireAuth><ServersPage /></RequireAuth>} />
        <Route path="/disks" element={<RequireAuth><DisksPage /></RequireAuth>} />
        <Route path="/*" element={<NotFoundPage />}/>
      </Route>
    </Routes>
  )

  function PageLayout() {
    const { isAuthenticated, user } = useAuth0();

    const showNav = isAuthenticated && user;//&& user[`${config.AUTH0_CLAIM_NAMESPACE}/organization_id`];
    return <div className="App">
      <section className="uk-section uk-padding-remove-top uk-padding-remove-bottom">
        <PageHeader />
        {showNav && <PrimaryNav />}
      </section>
      <section className="uk-section uk-padding-remove-top uk-padding-remove-bottom">
        <div className="uk-conatiner uk-padding" >
          <Outlet />
        </div>
      </section>
    </div >
  }

}

function IndexRoute() {
  const { isAuthenticated, user, isLoading } = useAuth0();

  if (isLoading) {
    return <Spinner />
  }

  const isNewUser = true;

  if (isAuthenticated) {
    if (user) {
      return <div className="uk-container"><HomePage /></div>
    }
  }
  return <div className="uk-container"><IndexPage /></div>
}

function ProtectedRoute(props: RouteProps) {
  return <Route
    path={props.path}
    element={<RequireAuth>{props.element}</RequireAuth>}
  />
}

function RequireAuth(props: RouteProps) {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Spinner />
  }
  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  return <div className="uk-container uk-container-expand">{props.children}</div>;
}

const NotFoundPage = () => {
  return <div className="uk-container">
    <h2>Hmm.... </h2>
    <p>
      We know where a lot of things are, but not whatever you just tried to find.  Try the <Link to="/">Dashboard</Link>.
      <img src={Hedgehog} />
    </p>

  </div>
}

export default App
