import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import flaxLogo from '../assets/images/flax-logo.svg';

// import SVG from 'react-inlinesvg';


export const FlaxLogo = (props: React.HTMLAttributes<HTMLImageElement>) =>  {
  const navigate = useNavigate();
  return <a onClick={() => navigate('/')}><img src={flaxLogo} alt="Sesame Logo" width="180px"
    className="uk-margin-left uk-margin-botton" /></a>
}

